.error{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span{
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
  }
}
@primary-color: #1890ff;